<template>
  <section class="cta-section" id="cta">
    <h2>Sign Up Today</h2>
    <button @click="openForm">Join Our Pre-Launch Program</button>

    <!-- Include the Customizable Form -->
    <CustomizableForm
      v-if="isFormVisible"
      :category="'general'" 
      @close="isFormVisible = false"
    />
  </section>
</template>

<script>
import CustomizableForm from "@/components/CustomizableForm.vue";

export default {
  components: {
    CustomizableForm,
  },
  data() {
    return {
      isFormVisible: false,
    };
  },
  methods: {
    openForm() {
      this.isFormVisible = true; // Show the form
    },
  },
};
</script>

<style scoped>
.cta-section {
  padding: 50px 20px;
  text-align: center;
  background-color: #2a2a2a;
}

.cta-section h2 {
  margin-bottom: 20px;
  font-size: 2rem;
  color: white;
}

.cta-section button {
  padding: 10px 20px;
  font-size: 1.2rem;
  color: white;
  background-color: #28a745;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.cta-section button:hover {
  background-color: #218838;
}

/* Optional: Add styling for mobile devices */
@media (max-width: 768px) {
  .cta-section h2 {
    font-size: 1.8rem;
  }

  .cta-section button {
    font-size: 1rem;
    padding: 10px 15px;
  }
}
</style>
