<template>
  <footer class="footer-section">
    <p>Contact Us: info@plankton.lighting | +1 (365) 299-2901</p>
    <p>&copy; 2024 Plankton by Suprematics Solutions. All rights reserved.</p>
  </footer>
</template>

<style scoped>
.footer-section {
  padding: 20px;
  text-align: center;
  background-color: #1a1a1a;
  font-size: 0.9rem;
  color: #888;
}
</style>
