<template>
  <div class="form-modal">
    <div class="form-content">
      <!-- Form Title -->
      <h2 v-if="category === 'manufacturer'">Request a Demo</h2>
      <h2 v-if="category === 'designer'">Join the Waitlist</h2>
      <h2 v-if="category === 'marketplace'">Contact Us</h2>
      <h2 v-if="category === 'general'">Join Our Pre-Launch Program</h2>

      <!-- Form Fields -->
      <form @submit.prevent="handleSubmit">
        <!-- Common Fields -->
        <div class="form-group">
          <label for="name">Name</label>
          <input type="text" id="name" v-model="formData.name" placeholder="Enter your name" required />
        </div>

        <div class="form-group">
          <label for="email">Email</label>
          <input type="email" id="email" v-model="formData.email" placeholder="Enter your email" required />
        </div>

        <!-- Manufacturer-Specific Fields -->
        <div v-if="category === 'manufacturer'">
          <div class="form-group">
            <label for="company">Company Name</label>
            <input type="text" id="company" v-model="formData.company" placeholder="Enter your company name" />
          </div>
          <div class="form-group">
            <label for="products">Product Categories</label>
            <input type="text" id="products" v-model="formData.products" placeholder="e.g., Residential, Commercial" />
          </div>
        </div>

        <!-- Designer-Specific Fields -->
        <div v-if="category === 'designer'">
          <div class="form-group">
            <label for="firm">Design Firm (optional)</label>
            <input type="text" id="firm" v-model="formData.firm" placeholder="Enter your design firm" />
          </div>
          <div class="form-group">
            <label for="interest">Areas of Interest</label>
            <input type="text" id="interest" v-model="formData.interest" placeholder="e.g., Lighting for Homes" />
          </div>
        </div>

        <!-- Marketplace-Specific Fields -->
        <div v-if="category === 'marketplace'">
          <div class="form-group">
            <label for="platform">Platform Name</label>
            <input type="text" id="platform" v-model="formData.platform" placeholder="Enter your platform name" />
          </div>
          <div class="form-group">
            <label for="integration">Integration Interest</label>
            <input type="text" id="integration" v-model="formData.integration" placeholder="API/White Label" />
          </div>
        </div>

        <!-- Message Field -->
        <div class="form-group">
          <label for="message">Message</label>
          <textarea
            id="message"
            v-model="formData.message"
            placeholder="Write your message here (optional)"
            rows="4"
          ></textarea>
        </div>

        <!-- Buttons -->
        <div class="form-buttons">
          <button type="submit" class="submit-button">Submit</button>
          <button type="button" class="cancel-button" @click="$emit('close')">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    category: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      formData: {
        name: '',
        email: '',
        company: '',
        products: '',
        firm: '',
        interest: '',
        platform: '',
        integration: '',
        message: '',
      },
    };
  },
  methods: {
  async handleSubmit() {
    try {
      const response = await fetch('https://ds97cmyajf.execute-api.us-east-1.amazonaws.com/dev/sign_up', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          category: this.category,
          name: this.formData.name,
          email: this.formData.email,
          company: this.formData.company || null,
          message: this.formData.message || null,
          additional_data: {
            products: this.formData.products || null,
            firm: this.formData.firm || null,
            interest: this.formData.interest || null,
            platform: this.formData.platform || null,
            integration: this.formData.integration || null,
          },
        }),
      });

      const result = await response.json();
      if (response.ok) {
        alert('Your request has been submitted successfully!');
        this.$emit('close'); // Close the form
      } else {
        alert(`Error: ${result.error}`);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('Failed to submit your request. Please try again.');
    }
  },
},

};
</script>

<style scoped>
/* Modal Styles */
.form-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.form-content {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  width: 90%;
  max-width: 600px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Form Group */
.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  font-size: 1rem;
  color: #333;
  margin-bottom: 5px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s;
}

.form-group input:focus,
.form-group textarea:focus {
  border-color: #007bff;
}

/* Buttons */
.form-buttons {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.submit-button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #0056b3;
}

.cancel-button {
  background-color: #ccc;
  color: #333;
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.cancel-button:hover {
  background-color: #999;
}
</style>
