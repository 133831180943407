<template>
  <section class="features-section light-background">
    <h2>Why Plankton?</h2>
    <p class="features-subtitle">
      Meet our tailored solutions for manufacturers, designers, and marketplaces.
    </p>

    <!-- Features Grid -->
    <div class="features-container">
      <!-- Lighting Manufacturers -->
      <div id="features-manufacturers" class="feature manufacturers">
        <h3>Empower Your Products with 3D Visualization</h3>
        <ul>
          <li>Customizable virtual showrooms that drive sales.</li>
          <li>Real-time interactions to showcase quality and features.</li>
          <li>Performance insights to track engagement.</li>
          <li>Enhance brand visibility through immersive product experience.</li>
        </ul>
        <img src="@/assets/dedo-like2.jpg" alt="Manufacturers Feature" />
        <button @click="openForm('manufacturer')">Request a Demo</button>
      </div>

      <!-- End Users -->
      <div id="features-designers" class="feature end-users">
        <h3>Design Spaces with Ease</h3>
        <ul>
          <li>Experiment with advanced lighting techniques.</li>
          <li>Visualize real-life settings with customizable templates.</li>
          <li>Combine artistic visuals with accurate illumination calculations.</li>
          <li>Share designs with clients or contractors.</li>
        </ul>
        <img src="@/assets/end-users-feature.jpg" alt="End Users Feature" />
        <button @click="openForm('designer')">Join the Waitlist</button>
      </div>

      <!-- Marketplaces -->
      <div id="features-marketplaces" class="feature marketplaces">
        <h3>Simplify Lighting Choices with Plankton Tools</h3>
        <ul>
          <li>Interactive visualization tools for your platform.</li>
          <li>Seamless API integration to simplify adoption.</li>
          <li>Enhanced buyer confidence and engagement.</li>
          <li>Streamline product discovery with intelligent lighting recommendations.</li>
        </ul>
        <img src="@/assets/marketplaces-feature.jpg" alt="Marketplaces Feature" />
        <button @click="openForm('marketplace')">Contact Us</button>
      </div>
    </div>

    <!-- Customizable Form Modal -->
    <CustomizableForm
      v-if="isFormVisible"
      :category="selectedCategory"
      @close="isFormVisible = false"
    />
  </section>
</template>

<script>
import CustomizableForm from "@/components/CustomizableForm.vue";

export default {
  components: {
    CustomizableForm,
  },
  data() {
    return {
      isFormVisible: false,
      selectedCategory: null,
    };
  },
  methods: {
    openForm(category) {
      this.selectedCategory = category; // Set the category based on button clicked
      this.isFormVisible = true; // Show the form
    },
  },
};
</script>

<style scoped>
.features-section {
  padding: 50px 20px;
  text-align: center;
}

.features-subtitle {
  margin-top: 10px;
  font-size: 1.2rem;
  color: #666;
}

.features-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  margin-top: 30px;
}

.feature {
  padding: 20px;
  background: white;
  border-radius: 8px;
  text-align: left;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.feature h3 {
  font-size: 1.5rem;
  margin-bottom: 15px;
}

.features-section h2 {
  font-size: 2rem;
  color: black;
  margin-bottom: 20px;
}

.features-section.light-background {
  background-color: #f5f5f5;
  color: black;
}

.feature ul {
  margin-bottom: 15px;
  list-style: none;
  padding: 0;
}

.feature ul li {
  margin-bottom: 10px;
  font-size: 1rem;
  line-height: 1.5;
  color: #333;
}

.feature img {
  width: 100%;
  height: auto;
  margin-bottom: 15px;
  border-radius: 5px;
}

.feature button {
  padding: 10px 20px;
  font-size: 1rem;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.feature button:hover {
  background-color: #0056b3;
}

.manufacturers {
  border-top: 5px solid #007bff;
}

.end-users {
  border-top: 5px solid #28a745;
}

.marketplaces {
  border-top: 5px solid #ffcc00;
}

/* Ensure proper scroll positioning with a fixed header */
.feature {
  scroll-margin-top: 80px; /* Adjust for header height */
}
</style>
