<template>
  <section class="hero-section">
    <div class="hero-content">
      <img src="@/assets/PlanktonLogoWithText_1200px.png" alt="Plankton Logo" class="hero-logo" />
      <h1>Transform the Future of Lighting Design</h1>
      <p>
        Discover how Plankton empowers <span class="highlight">manufacturers</span>,
        <span class="highlight">designers</span>, and <span class="highlight">marketplaces</span>
        with interactive 3D lighting solutions.
      </p>
      <div class="cta-buttons">
        <button @click="scrollToSection('features-manufacturers')">For Manufacturers</button>
        <button @click="scrollToSection('features-designers')">For Designers</button>
        <button @click="scrollToSection('features-marketplaces')">For Marketplaces</button>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  methods: {
    scrollToSection(sectionId) {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      } else {
        console.error(`Section with ID "${sectionId}" not found.`);
      }
    },
  },
};
</script>


<style scoped>
.hero-section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url('@/assets/hero-background.jpg'); /* Replace with your image */
  background-size: cover;
  background-position: center;
  text-align: center;
  color: white;
  padding: 0 20px;
}

.hero-logo {
  width: 200px; /* Adjust the size of the logo */
  height: auto;
  margin-bottom: 20px;
}

.hero-content {
  max-width: 800px;
}

.hero-content h1 {
  font-size: 3rem;
  margin-bottom: 20px;
  font-weight: bold;
}

.hero-content p {
  font-size: 1.2rem;
  margin-bottom: 30px;
}

.hero-content .highlight {
  color: #ffcc00;
  font-weight: bold;
}

.cta-buttons {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.cta-buttons button {
  padding: 10px 20px;
  font-size: 1rem;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cta-buttons button:hover {
  background-color: #0056b3;
}
</style>
