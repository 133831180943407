<template>
  <div id="app">
    <HeroSection />
    <FeaturesSection />
    <CTASection />
    <FooterSection />
  </div>
</template>

<script>
import HeroSection from './components/HeroSection.vue';
import FeaturesSection from './components/FeaturesSection.vue';
import CTASection from './components/CTASection.vue';
import FooterSection from './components/FooterSection.vue';

export default {
  components: {
    HeroSection,
    FeaturesSection,
    CTASection,
    FooterSection,
  },
};
</script>

<style>
body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  background-color: #121212;
  color: white;
}
</style>

